import React from "react";
//import axios from "axios";
//import qs from "qs";
import Table from "./Table";
import InputField from "./InputField";
import drengenavne from "./boys_alph.json";
import pigenavne from "./girls_alph.json";
import unisex from "./unisex_alph.json";
import drengenavne_pop from "./boys_count.json";
import pigenavne_pop from "./girls_count.json";
import unisex_pop from "./unisex_count.json";
import _, { map } from "underscore";
import { initializeApp } from "firebase/app";
//import { getDatabase } from "firebase/database";
import { getFirestore, collection, doc, setDoc, getDocs } from "firebase/firestore";
var s = require("underscore.string");

const Gender = Object.freeze({
  Girl: Symbol("girl"),
  Boy: Symbol("boy"),
  Unisex: Symbol("unisex"),
});

const Sorting = Object.freeze({
  Alph: Symbol("alph"),
  Pop: Symbol("pop"),
});

class TableSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      starts_with: "",
      ends_with: "",
      contains: "",
      cannot_start_with: "",
      cannot_end_with: "",
      cannot_contain: "",
      minimum_chars: 0,
      maximum_chars: 15,
      gender: Gender.Boy,
      sorting: Sorting.Alph,
      is_boy: true,
      prettyGender: "Dreng",
    };
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCmYqInGLUKgWtMYESCIX95swZvfw791Sk",
  authDomain: "baby-names-ff1f8.firebaseapp.com",
  projectId: "baby-names-ff1f8",
  storageBucket: "baby-names-ff1f8.appspot.com",
  messagingSenderId: "706276128966",
  appId: "1:706276128966:web:ba990d72b4e4c450b33fc7"
};

// Initialize Firebase
    const app = initializeApp(firebaseConfig);

    const db = getFirestore(app);

    this.getCounters(db);
  }

async getCounters(db) {
    const querySnapshot = await getDocs(collection(db, "counters"));
    querySnapshot.forEach((e) => {
        const counter = e.data().counter;
        this.updateCounter(counter, db);
    });
}

async updateCounter(c, db) {
    const counterRef = collection(db, "counters");
    await setDoc(doc(counterRef, "counter"), {
        counter: c + 1
    });
}

componentDidMount(){
    document.title = "Navngivning"
  }

  filterByStartsWith(names) {
    return this.needsToFunction(this.state.starts_with, names, s.startsWith);
  }

  filterByEndsWith(names) {
    return this.needsToFunction(this.state.ends_with, names, s.endsWith);
  }

  filterByContains(names) {
    return this.needsToFunction(this.state.contains, names, s.include);
  }

  needsToFunction(words, names, functor) {
    var contains = _.filter(s.words(words, ","), function (pre_name) {
      return !_.isEmpty(s.trim(pre_name));
    });

    var pre_chunk = _.filter(names, function (name) {
      if (_.isEmpty(s.trim(contains))) {
        return true;
      } else {
        return _.reduce(
          contains,
          function (memo, elem) {
            return (
              memo ||
              functor(name.name.toLowerCase(), s.trim(elem).toLowerCase())
            );
          },
          false
        );
      }
    });

    return pre_chunk;
  }

  filterCannotContain(names) {
    return this.cannotFunction(this.state.cannot_contain, names, s.include);
  }

  filterCannotStartWith(names) {
    return this.cannotFunction(
      this.state.cannot_start_with,
      names,
      s.startsWith
    );
  }

  filterCannotEndWith(names) {
    return this.cannotFunction(this.state.cannot_end_with, names, s.endsWith);
  }

  cannotFunction(words, names, functor) {
    var does_not = _.filter(s.words(words, ","), function (pre_name) {
      return !_.isEmpty(s.trim(pre_name));
    });

    var pre_chunk = _.filter(names, function (name) {
      if (_.isEmpty(s.trim(does_not))) {
        return true;
      } else {
        return _.reduce(
          does_not,
          function (memo, elem) {
            return (
              memo &&
              !functor(name.name.toLowerCase(), s.trim(elem).toLowerCase())
            );
          },
          true
        );
      }
    });

    return pre_chunk;
  }

  // TODO: We need assertion on min < max
  filterByLength(names) {
    var minimum = this.state.minimum_chars;
    var maximum = this.state.maximum_chars;
    var res = _.filter(names, function (name) {
      return _.size(name.name) <= maximum && _.size(name.name) >= minimum;
    });
    return res;
  }

  getInitialListOfNames() {
    if (this.state.sorting === Sorting.Alph) {
      if (this.state.gender === Gender.Boy) {
        return drengenavne;
      } else if (this.state.gender === Gender.Girl) {
        return pigenavne;
      } else {
        return unisex;
      }
    } else {
      if (this.state.gender === Gender.Boy) {
        return drengenavne_pop;
      } else if (this.state.gender === Gender.Girl) {
        return pigenavne_pop;
      } else {
        return unisex_pop;
      }
    }
  }

  getData(page) {
    var pre_chunk = this.getInitialListOfNames();
    pre_chunk = this.filterByLength(pre_chunk);
    pre_chunk = this.filterByStartsWith(pre_chunk);
    pre_chunk = this.filterByEndsWith(pre_chunk);
    pre_chunk = this.filterByContains(pre_chunk);
    pre_chunk = this.filterCannotStartWith(pre_chunk);
    pre_chunk = this.filterCannotContain(pre_chunk);
    pre_chunk = this.filterCannotEndWith(pre_chunk);
    var chunk = _.chunk(pre_chunk, 10);
    var current = chunk[page];
    /*var mapped = _.map(current, function (e) {
      return { name: e };
    });*/

    // Chunk er et array af arrays. Vi tager et af dem og tæller hvor mange arrays af arrays der er i alt. Det fungerer så længe vi sætter current_page
    var obj = {
      data: current,
      current_page: page,
      pages: _.size(chunk),
    };

    /*const data = {
      is_boy: this.state.is_boy,
      is_girl: false,
      is_unisex: false,
      starts_with: this.state.starts_with,
      ends_with: this.state.ends_with,
      contains: this.state.contains,
      cannot_end_with: this.state.cannot_end_with,
      cannot_start_with: this.state.cannot_start_with,
      cannot_contain: this.state.cannot_contain,
      minimum_chars: this.state.minimum_chars,
      maximum_chars: this.state.maximum_chars,
      page,
    };*/
    this.setState({ data: obj });
    this.setState({ currentPage: obj.current_page });
  }

  showNextPage() {
    this.getData(this.state.data.current_page + 1);
  }

  showPrevPage() {
    this.getData(this.state.data.current_page - 1);
  }

  renderNextButton() {
    if (this.state.data.current_page + 1 < this.state.data.pages) {
      return (
        <div className="field">
          <button
            className="button is-pulled-right is-primary"
            onClick={() => this.showNextPage()}
          >
            Frem
          </button>
        </div>
      );
    }
  }

  renderPrevButton() {
    if (this.state.data.current_page > 0) {
      return (
        <div className="field">
          <button
            className="button is-pulled-left is-primary"
            onClick={() => this.showPrevPage()}
          >
            Tilbage
          </button>
        </div>
      );
    }
  }

  renderTable() {
    if (this.state.data) {
      return (
        <div className="block">
          <Table value={this.state.data} />
          <br />
          <div>{this.renderPrevButton()}</div>
          <div>{this.renderNextButton()}</div>
        </div>
      );
    }
    return <div> </div>;
  }

  render() {
    return (
      <div className="container">
        <div className="notification">
          <div className="py-1">
            <div className="mt-8 max-w-md">
              <div className="grid grid-cols-1 gap-6">
                <div className="columns">
                  <div className="column">
                    <label className="label">Køn</label>
                    <div className="select">
                      <select
                        className="input is-primary"
                        value={this.state.prettyGender}
                        onChange={(evt) => this.updateGender(evt)}
                      >
                        <option value="Dreng">Dreng</option>
                        <option value="Pige">Pige</option>
                        <option value="Unisex">Unisex</option>
                      </select>
                    </div>
                  </div>
                  <div className="column">
                    <label className="label">Sortering</label>
                    <div className="select">
                      <select
                        className="input is-primary"
                        value={this.state.prettySorting}
                        onChange={(evt) => this.updateSorting(evt)}
                      >
                        <option value="Dreng">Alfabetisk</option>
                        <option value="Pige">Popularitet</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <InputField
                      example="f.eks. M,K,Ras"
                      label="Skal starte med"
                      value={this.state.starts_with}
                      updateFunc={(evt) => this.updateStartsWith(evt)}
                    />
                  </div>
                  <div className="column">
                    <InputField
                      example="f.eks. M,Z,K,Æ"
                      label="Må ikke starte med"
                      value={this.state.cannot_start_with}
                      updateFunc={(evt) => this.updateCannotStartWith(evt)}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <InputField
                      example="f.eks. ds,t,rl"
                      label="Skal slutte med"
                      value={this.state.ends_with}
                      updateFunc={(evt) => this.updateEndsWith(evt)}
                    />
                  </div>
                  <div className="column">
                    <InputField
                      example="f.eks. ds,a,h"
                      label="Må ikke slutte med"
                      value={this.state.cannot_end_with}
                      updateFunc={(evt) => this.updateCannotEndWith(evt)}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <InputField
                      example="f.eks. a,i,th,k"
                      label="Skal indeholde"
                      value={this.state.contains}
                      updateFunc={(evt) => this.updateContain(evt)}
                    />
                  </div>
                  <div className="column">
                    <InputField
                      example="f.eks. æ,ø,å"
                      label="Må ikke indeholde"
                      value={this.state.cannot_contain}
                      updateFunc={(evt) => this.updateCannotContain(evt)}
                    />
                  </div>
                </div>
                <div>
                  {" "}
                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <label className="label">Min. antal tegn </label>
                        <div className="select">
                          <select
                            className="input is-primary"
                            value={this.state.minimum_chars}
                            onChange={(evt) => this.updateMinimumChars(evt)}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label">Max. antal tegn </label>
                        <div className="select">
                          <select
                            className="input is-primary"
                            value={this.state.maximum_chars}
                            onChange={(evt) => this.updateMaximumChars(evt)}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div className="block">
                  <button
                    className="button is-primary is-pulled-right"
                    onClick={() => this.getData(0)}
                  >
                    Søg
                  </button>
                </div>
                <div className="columns">
                  <div className="column is-one-fifth"> </div>
                  <div className="column">
                    <div className="block">{this.renderTable()}</div>
                  </div>
                  <div className="column is-one-fifth"> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification">
        <div className="content">
        <h1>Kilder</h1>
        <p> Alle godkendte danske navne og deres popularitet er fundet på <a href="https://familieretshuset.dk/navne/navne/godkendte-fornavne">Familieretshuset hjemmeside</a> og <a href="https://dst.dk">Danmarks Statistiks hjemmeside</a>. Den præcise liste brugt på denne side er modificeret og sammensat af os og er derfor en smule anderledes.</p>
        </div>
        </div>
      </div>
    );
  }

  getEnumByString(str) {
    // Can this be done nicer? Some kinda of `from` function?
    if (str === "Dreng") {
      return Gender.Boy;
    }
    if (str === "Pige") {
      return Gender.Girl;
    }
    if (str === "Unisex") {
      return Gender.Unisex;
    }
    if (str === "Alfabetisk") {
        return Sorting.Alph;
    }
    if (str === "Popularitet") {
        return Sorting.Pop;
    }
  }

  updateGender(evt) {
    const val = evt.target.value;
    const gender = this.getEnumByString(val);
    this.setState({
      prettyGender: val,
      gender,
    });
  }

updateSorting(evt) {
    const val = evt.target.value;
    const sorting = this.getEnumByString(val);
    this.setState({
      prettySorting: val,
      sorting,
    });
  }


  updateMinimumChars(evt) {
    const val = evt.target.value;
    this.setState({
      minimum_chars: val,
    });
  }

  updateMaximumChars(evt) {
    const val = evt.target.value;
    this.setState({
      maximum_chars: val,
    });
  }

  updateStartsWith(evt) {
    const val = evt.target.value;
    this.setState({
      starts_with: val,
    });
  }

  updateEndsWith(evt) {
    const val = evt.target.value;
    this.setState({
      ends_with: val,
    });
  }

  updateContain(evt) {
    const val = evt.target.value;
    this.setState({
      contains: val,
    });
  }

  updateCannotStartWith(evt) {
    const val = evt.target.value;
    this.setState({
      cannot_start_with: val,
    });
  }

  updateCannotEndWith(evt) {
    const val = evt.target.value;
    this.setState({
      cannot_end_with: val,
    });
  }

  updateCannotContain(evt) {
    const val = evt.target.value;
    this.setState({
      cannot_contain: val,
    });
  }
}

export default TableSite;
