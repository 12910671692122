import React from "react";

class InputField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="field">
        <label className="label">{this.props.label}</label>
        <div className="control">
          <input
            type="text"
            placeholder={this.props.example}
            className="input is-primary"
            value={this.props.value}
            onChange={(evt) => this.props.updateFunc(evt)}
          />
        </div>
      </div>
    );
  }
}

export default InputField;
