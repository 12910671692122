import React from "react";
import ReactDOM from "react-dom";
import TableSite from "./TableSite";
import "bulma/css/bulma.min.css";

ReactDOM.render(
  <React.StrictMode>
    <TableSite />
  </React.StrictMode>,
  document.getElementById("root")
);
