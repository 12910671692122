import React from "react";

class Table extends React.Component {
  constructor(props) {
    super(props);
  }

  // TODO: NEEDS TO TAKE EMPTY LIST OR NULL INTO ACCOUNT
  render() {
      if (this.props.value && this.props.value.data) { 
    return (
      <div className="block">
        <table
          key="table"
          className="table is-fullwidth is-hoverable is-striped"
        >
          <thead key="thead">
            <tr key="thead-tr">
              <th key="thead-th">Navn</th>
            </tr>
          </thead>
          <tbody key="tbody">
            {this.props.value.data.map((element, index) => (
              <tr data-index={index} key={index}>
                <td key={element.name}>{element.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
      } else {
          return (
              <div className="block"><p> Ingen navne fundet</p></div>
          );
      }
  }
}

export default Table;
